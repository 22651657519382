import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureCurrentUser, ensureOwnListing } from '../../util/data';
import { IconSpinner, ListingLink, Modal, TermsOfService } from '../../components';
import { EditListingDetailsForm } from '../../forms';
import { queryAllUsers } from '../../util/api';

import css from './EditListingDetailsPanel.module.css';
import moment from 'moment';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';
import { connect } from 'react-redux';
const { Money, LatLng } = sdkTypes;

class EditListingDetailsPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);
    this.getBookings = this.getBookings.bind(this);
    this.state = {
      initialValues: this.getInitialValues(),
      tosModalOpen: false,
      interpreters: null,
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData, privateData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places

    const interpretersNeeded = publicData.interpretersNeeded;

    const cpType = publicData.cpType;
    const tos = publicData.tos;

    const preferredSex = publicData.preferredSex;
    const interpreterPrefs = privateData?.interpreterPrefs;
    const { preferredInterpreter, preferredInterpreter2 } = interpreterPrefs
      ? interpreterPrefs
      : {};
    let customInterpreterName, customInterpreterName2;
    if (preferredInterpreter?.key == 9999) {
      customInterpreterName = preferredInterpreter.label;
    }
    if (preferredInterpreter2?.key == 9999) {
      customInterpreterName2 = preferredInterpreter2.label;
    }
    return {
      interpretersNeeded,
      cpType,
      tos,
      preferredSex,
      preferredInterpreter,
      preferredInterpreter2,
      customInterpreterName,
      customInterpreterName2,
    };
  }
  getBookings = async () => {
    try {
      const response = await queryAllUsers();
      this.setState({ interpreters: response.list });
    } catch (e) {
      console.log(e);
    }
  };
  componentDidMount() {
    this.getBookings();
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      currentUser,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      onManageDisableScrolling,
    } = this.props;
    const user = ensureCurrentUser(currentUser);
    const userTypes = user.attributes.profile.privateData.userTypes;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const { publicData, description, privateData } = currentListing.attributes;

    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    // tidy
    const panelTitle = userTypes.includes('contractCustomer') ? (
      'Where is the booking?'
    ) : isPublished ? (
      <FormattedMessage
        id="EditListingBookingDetailsPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingBookingDetailsPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingBookingDetailsPanel.createListingTitle" />
    );

    let initialValues = {
      interpretersNeeded: publicData.interpretersNeeded,
      cpType: publicData.cpType,
      tos: publicData.tos,
      preferredSex: publicData.preferredSex,
      preferredInterpreter: privateData?.interpreterPrefs?.preferredInterpreter,
      preferredInterpreter2: privateData?.interpreterPrefs?.preferredInterpreter2,
      customInterpreterName: privateData?.interpreterPrefs?.preferredInterpreter
        ? privateData?.interpreterPrefs?.preferredInterpreter?.key == 9999
          ? privateData?.interpreterPrefs?.preferredInterpreter.label
          : null
        : null,
      customInterpreterName2: privateData?.interpreterPrefs?.preferredInterpreter
        ? privateData?.interpreterPrefs?.preferredInterpreter2?.key == 9999
          ? privateData?.interpreterPrefs?.preferredInterpreter2.label
          : null
        : null,
    };

    if (updateInProgress) {
      initialValues = {
        ...initialValues,
        ...(this.state.initialValues?.publicData || {}),
        ...(this.state.initialValues?.privateData || {}),
      };
    }

    return this.state.fetchLoading ? (
      <div className={classes}>
        <div className={css.spinnerContainer}>
          <IconSpinner className={css.spinner} />
        </div>
      </div>
    ) : (
      <div className={classes}>
        <h1 className={css.title}>{panelTitle}</h1>
        <EditListingDetailsForm
          className={css.form}
          initialValues={initialValues}
          userTypes={userTypes}
          publicData={publicData}
          privateData={privateData}
          interpreters={this.state.interpreters}
          onSubmit={async values => {
            let {
              interpretersNeeded,
              cpType,
              preferredSex,
              tos,
              preferredInterpreter,
              preferredInterpreter2,
              customInterpreterName,
              customInterpreterName2,
            } = values;
            if (customInterpreterName) {
              preferredInterpreter.label = customInterpreterName;
            }
            if (customInterpreterName2) {
              preferredInterpreter2.label = customInterpreterName2;
            }
            let updateValues = {
              publicData: {
                interpretersNeeded: Number(interpretersNeeded),
                cpType,
                tos,
                favouriteListingId: currentListing?.id?.uuid,
                preferredSex,
              },
              privateData: {
                // Putting these 2 props on the booking itself facilitates `server/index` (and anything else)
                // doing work like pinging CRMs, without having to first do convoluted SDK operations.
                userEmail: user.attributes.email,
                userEmailVerified: user.attributes.emailVerified,

                interpreterPrefs: {
                  preferredInterpreter,
                  preferredInterpreter2,
                  // preferredInterpreter: customInterpreterName
                  //   ? {
                  //       key: preferredInterpreter.key,
                  //       label: customInterpreterName,
                  //     }
                  //   : preferredInterpreter,
                  // preferredInterpreter2: customInterpreterName2
                  //   ? {
                  //       key: preferredInterpreter2.key,
                  //       label: customInterpreterName2,
                  //     }
                  //   : preferredInterpreter2,
                },
              },
            };
            updateValues = {
              ...updateValues,
              publicData: {
                ...updateValues.publicData,
              },
              privateData: {
                ...updateValues.privateData,
              },
            };

            this.setState({
              initialValues: {
                interpretersNeeded,
                cpType,
                tos,
                preferredSex,
                preferredInterpreter,
                preferredInterpreter2,
                customInterpreterName,
                customInterpreterName2,
              },
            });
            onSubmit(updateValues);
          }}
          onChange={onChange}
          saveActionMsg={submitButtonText}
          disabled={disabled}
          ready={ready}
          updated={panelUpdated}
          updateInProgress={updateInProgress}
          fetchErrors={errors}
          openTermsOfService={() => this.setState({ tosModalOpen: true })}
        />
        <Modal
          id="CheckoutPage.tos"
          isOpen={this.state.tosModalOpen}
          onClose={() => this.setState({ tosModalOpen: false })}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          <div className={css.termsWrapper}>
            <h2 className={css.termsHeading}>
              <FormattedMessage id="AuthenticationPage.termsHeading" />
            </h2>
            <TermsOfService />
          </div>
        </Modal>
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingDetailsPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingDetailsPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingDetailsPanel;
