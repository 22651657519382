import React from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  IconSocialMediaLinkedin,
  Logo,
  ExternalLink,
  NamedLink,
} from '../../components';

import cuk from './assets/cuk_member.png';
import cse from './assets/social-enterprise-logo.png';
import lw from './assets/living-wage-employer.png';
import dc from './assets/disability-confident.png';
import wc from './assets/workers-coop.png';
import css from './Footer.module.css';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle, siteLinkedinPage } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });
  const goToLinkedin = intl.formatMessage({ id: 'Footer.goToLinkedin' });

  const twitterLink = siteTwitterPage ? (
    <ExternalLink
      key="linkToTwitter"
      href={siteTwitterPage}
      className={css.icon}
      title={goToTwitter}
    >
      <IconSocialMediaTwitter />
    </ExternalLink>
  ) : null;

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;

  const linkedinLink = siteLinkedinPage ? (
    <ExternalLink
      key="linkToLinkedin"
      href={siteLinkedinPage}
      className={css.icon}
      title={goToLinkedin}
    >
      <IconSocialMediaLinkedin />
    </ExternalLink>
  ) : null;

  return [twitterLink, fbLink, instragramLink, linkedinLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.links}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink} title="Signalise Home Page">
                <span className={css.logo}>
                  <Logo format="desktop" />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescription" />
                </p>
                <p className={css.organizationCopyright}>
                  <FormattedMessage id="Footer.copyright" />
                </p>
                <p className={css.organizationCopyright}>
                  <FormattedMessage id="Footer.registeredOffice" />
                </p>
              </div>
            </div>
            <div className={css.infoLinks}>
              <span className={css.infoLinksHeader}>Explore</span>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="JoinUsPage" className={css.link}>
                    <FormattedMessage id="Footer.toJoinUsPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="GetInvolvedPage" className={css.link}>
                    <FormattedMessage id="Footer.toGetInvolvedPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'invest' }} className={css.link}>
                    <FormattedMessage id="Footer.toInvestPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink
                    name="CMSPage"
                    params={{ pageId: 'social-impact' }}
                    className={css.link}
                  >
                    <FormattedMessage id="Footer.toSocialImpact" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'progress' }} className={css.link}>
                    <FormattedMessage id="Footer.toProgressPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ResourcesPage" className={css.link}>
                    <FormattedMessage id="Footer.toResourcesPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="CMSPage" params={{ pageId: 'blog' }} className={css.link}>
                    <FormattedMessage id="Footer.toBlogPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactPage" />
                  </NamedLink>
                </li>
              </ul>
            </div>

            <div className={css.socialLinks}>
              <div className={css.infoLinks}>
                <span className={css.infoLinksHeader}>Contact</span>
                <ul className={css.list}>
                  <li className={css.listItem}>
                    <a className={[css.link, css.contact].join(' ')} href="tel:01518080373">
                      Call: 0151 808 0373
                    </a>
                    <a className={[css.link, css.contact].join(' ')} href="tel:07723469028">
                      SMS: 07723 469028
                    </a>
                  </li>
                  <li className={css.listItem}>
                    <a
                      className={[css.link, css.contact].join(' ')}
                      href="mailto:bookings@signalise.coop"
                    >
                      bookings@signalise.coop
                    </a>
                  </li>
                </ul>
                <span className={css.socialHeading}>Follow</span>
                <div className={css.socialLinksContainer}>{socialMediaLinks}</div>
              </div>
            </div>

            <div className={css.infoLinks}>
              <span className={css.infoLinksHeader}>Legal</span>
              <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink name="PrivacyPolicyPage" className={css.legalLink}>
                      <FormattedMessage id="Footer.privacyPolicy" />
                    </NamedLink>
                  </li>
                  <li>
                    <NamedLink
                      name="CMSPage"
                      params={{ pageId: 'modern-slavery-statement' }}
                      className={css.legalLink}
                    >
                      <FormattedMessage id="Footer.modernSlaveryStatement" />
                    </NamedLink>
                  </li>
                </ul>
                <span className={css.infoLinksHeader}>Help</span>
                <ul className={css.tosAndPrivacy}>
                  {' '}
                  <li className={css.listItem}>
                    <NamedLink name="FAQPage" className={css.link}>
                      <FormattedMessage id="Footer.toFAQPage" />
                    </NamedLink>
                  </li>
                  <li className={css.listItem}>
                    <NamedLink name="HelpPage" className={css.link}>
                      <FormattedMessage id="Footer.toHelpPage" />
                    </NamedLink>
                  </li>
                </ul>
                <span className={css.infoLinksHeader}>Stay in touch</span>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="MailchimpSignupPage" className={css.mailchimpLink}>
                      <FormattedMessage id="Footer.mailchimpSignup" />
                    </NamedLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={css.copyrightAndTermsMobile}>
            <NamedLink name="LandingPage" className={css.logoLink}>
              <span className={css.logo}>
                <Logo format="desktop" />
              </span>
            </NamedLink>
            <FormattedMessage id="Footer.copyright" />
            <FormattedMessage id="Footer.registeredOffice" />
          </div>
          <div className={css.externalOrgLogos}>
            <img src={cuk} className={css.externalOrgLogo} alt="Cooperatives UK member logo" />
            <img
              src={cse}
              className={css.externalOrgLogo}
              alt="Certified social enterprise business for good logo"
            />
            <img src={lw} className={css.externalOrgLogo} alt="Living Wage Employer logo" />
            <img
              src={dc}
              className={classNames(css.externalOrgLogo, css.dc)}
              alt="Disability Confident Employer logo"
            />
            <img
              src={wc}
              className={classNames(css.externalOrgLogo, css.wc)}
              alt="Workers Co-op logo"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
