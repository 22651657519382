import React from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import arrayMutators from 'final-form-arrays';
import {
  autocompleteSearchRequired,
  autocompletePlaceSelected,
  composeValidators,
  required,
  validGPVideoConferenceTime,
  emailFormatValid,
} from '../../util/validators';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  FieldPhoneNumberInput,
  // FieldDateTimePicker,
  FieldSelect,
  FieldReactSelect,
  FieldAirbnbDateTimePicker,
  FieldCheckbox,
} from '../../components';
import css from './VideoConferencingForm.module.css';
import config from '../../config';
import moment from 'moment';
import data from '../../data/signalise.json';
const identity = v => v;

export const VideoConferencingForm = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        invalid,
        pristine,
        saveActionMsg,
        updated,
        updateInProgress,
        fetchErrors,
        values,
        openTermsOfService,
        isUserDeaf,
        isWirralCHC,
        isGPCall,
        isVRSCall,
        outOfHours,
        nonGPCustomer,
        pincode,
        contractCustomer,
        emailVerified,
        isWalton,
        isLUHFT,
      } = formRenderProps;

      const { currentUserShowError, submitError } = fetchErrors;

      const titleRequiredMessage = intl.formatMessage({ id: 'VideoConferencingForm.address' });
      const addressPlaceholderMessage = intl.formatMessage({
        id: 'VideoConferencingForm.addressPlaceholder',
      });
      const addressRequiredMessage = intl.formatMessage({
        id: 'VideoConferencingForm.addressRequired',
      });
      const addressNotRecognisedMessage = intl.formatMessage({
        id: 'VideoConferencingForm.addressNotRecognised',
      });
      const thirdPartyDetails = intl.formatMessage({
        id: 'VideoConferencingForm.thirdPartyDetails',
      });
      const phonePlaceholder = intl.formatMessage({
        id: 'ContactDetailsForm.phonePlaceholder',
      });
      const phoneLabel = intl.formatMessage({ id: 'VideoConferencingForm.phoneLabel' });
      const wirralCHCTimeLabel = intl.formatMessage({
        id: 'VideoConferencingForm.wirralCHCTimeLabel',
      });
      const handleTermsKeyUp = e => {
        // Allow click action with keyboard like with normal links
        if (e.keyCode === KEY_CODE_ENTER) {
          openTermsOfService();
        }
      };

      const termsLink = (
        <span
          className={css.termsLink}
          onClick={openTermsOfService}
          role="button"
          onKeyUp={handleTermsKeyUp}
        >
          <FormattedMessage id="VideoConferencingForm.termsLink" />
        </span>
      );
      const errorMessage = submitError ? <p className={css.error}>{submitError}</p> : null;
      const formDisabledVerifyEmail = !emailVerified ? (
        <p className={css.error}>
          <FormattedMessage id="VideoConferencingForm.verifyEmail" />
        </p>
      ) : null;

      // if ( contractCustomer ) {
      const signaliseLocations = data.map(item => {
        return {
          key: item.code ? item.code.trim() : null,
          label: item.code ? `${item.code.trim()} - ${item.department.trim()}` : null,
          email: item.email ? item.email.trim() : null,
          department: item.department ? item.department.trim() : null,
          address: item.address ? item.address : null,
          contractCode: item.contractCode ? item.contractCode : null,
        };
      });
      const found = config.custom.contractPincodes.find(c => c.key == pincode);
      const filteredLocations = found
        ? signaliseLocations?.filter(a => a.contractCode == found?.label)
        : 1000; //TODO quick hack to provide a value
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled =
        invalid || disabled || submitInProgress || (isGPCall && outOfHours) || !emailVerified;
      const reasonLabel = intl.formatMessage({ id: 'VideoConferencingForm.reasonLabel' });
      const reasonPlaceholder = intl.formatMessage({
        id: 'VideoConferencingForm.reasonPlaceholder',
      });
      const informationLabel = intl.formatMessage({ id: 'VideoConferencingForm.informationLabel' });
      const informationPlaceholder = intl.formatMessage({
        id: 'VideoConferencingForm.informationPlaceholder',
      });
      const preferredSexLabel = intl.formatMessage({
        id: 'VideoConferencingForm.preferredSexLabel',
      });
      const preferredSexPlaceholder = intl.formatMessage({
        id: 'VideoConferencingForm.preferredSexPlaceholder',
      });
      const dateTimeLabel = intl.formatMessage({ id: 'VideoConferencingForm.dateTimeLabel' });
      const dateTimePlaceholder = intl.formatMessage({
        id: 'VideoConferencingForm.dateTimePlaceholder',
      });

      const fetchCurrentUserError = currentUserShowError ? (
        <p className={css.error}>Current user load failed...</p>
      ) : null;

      const emailLabel = intl.formatMessage({
        id: 'VideoConferencingForm.emailLabel',
      });
      const thirdPartyNameLabel = intl.formatMessage({
        id: 'VideoConferencingForm.thirdPartyNameLabel',
      });
      const thirdPartyNamePlaceholder = intl.formatMessage({
        id: 'VideoConferencingForm.thirdPartyNamePlaceholder',
      });
      const emailPlaceholder = intl.formatMessage({
        id: 'SignupForm.emailPlaceholder',
      });
      const emailRequiredMessage = intl.formatMessage({
        id: 'SignupForm.emailRequired',
      });
      const emailRequired = required(emailRequiredMessage);
      const emailInvalidMessage = intl.formatMessage({
        id: 'SignupForm.emailInvalid',
      });
      const emailValid = emailFormatValid(emailInvalidMessage);

      return (
        <Form className={classes} onSubmit={handleSubmit}>
          {errorMessage}
          {fetchCurrentUserError}
          <section className={css.introSection}>
            {isUserDeaf && !isVRSCall ? (
              <div className={css.callingMessage}>
                You are calling... {isWalton && 'The Walton Centre - '}
                {isLUHFT && 'Liverpool University Hospital Foundations Trust'}
                <FieldTextInput
                  id="locationName"
                  name="locationName"
                  className={css.locationNameLabel}
                  placeholder={'Name of the organisation you are calling'}
                  disabled={true}
                />
                {isWirralCHC && <p>{wirralCHCTimeLabel}</p>}
                {isGPCall && outOfHours && (
                  <p>
                    Opening hours are between 8:00 and 18:30. Please come back between these hours
                    to make a call.
                  </p>
                )}
              </div>
            ) : null}
            <p>
              Please fill out this quick form to be connected to an online British Sign Language
              interpreter.
            </p>

            {!isUserDeaf ? (
              <div>
                <p>This service is available 24/7 and can be used for:</p>
                <ul>
                  <li>short appointments e.g. making an appointment or a telephone triage call</li>
                  <li>emergencies before an interpreter can be on site</li>
                </ul>

                <p>
                  This can be used for on demand calls or bookings made in advance. The patient can
                  be present for the call or we can connect to them remotely.
                </p>
              </div>
            ) : null}
          </section>
          {!isUserDeaf ? (
            <div className={css.elementMargin}>
              <FieldAirbnbDateTimePicker
                name="startAt"
                id="startAt"
                label={dateTimeLabel}
                placeholder={dateTimePlaceholder}
                validate={composeValidators(required('Date and time is required'))}
                minDate={moment(new Date())
                  .startOf('day')
                  .toISOString()}
              />
            </div>
          ) : null}

          <div className={css.elementMargin}>
            <FieldTextInput
              className={css.building}
              name="reason"
              type="textarea"
              id="reason"
              label={reasonLabel}
              placeholder={reasonPlaceholder}
              validate={required('This field is required')}
            />
          </div>
          <div className={css.elementMargin}>
            <FieldSelect
              id="preferredGender"
              name="preferredGender"
              label={preferredSexLabel}
              placeholder={preferredSexPlaceholder}
            >
              <option disabled value="">
                Choose either...
              </option>
              {config.custom.prefSexOptions.map(g => (
                <option key={g.key} value={g.label}>
                  {g.label}
                </option>
              ))}
            </FieldSelect>
            {isVRSCall && (
              <div className={css.elementMargin}>
                <FieldPhoneNumberInput
                  className={css.features}
                  name="phoneNumber"
                  id={'phoneNumber'}
                  label={phoneLabel}
                  placeholder={phonePlaceholder}
                />
              </div>
            )}
          </div>
          <div className={css.sectionContainer}>
            <div className={css.elementMargin}>
              {!isUserDeaf && !isVRSCall ? (
                <LocationAutocompleteInputField
                  name="location"
                  label={titleRequiredMessage}
                  placeholder={addressPlaceholderMessage}
                  useDefaultPredictions={false}
                  format={identity}
                  valueFromForm={values.location}
                  validate={composeValidators(
                    autocompleteSearchRequired(addressRequiredMessage),
                    autocompletePlaceSelected(addressNotRecognisedMessage)
                  )}
                />
              ) : null}
            </div>
            {contractCustomer && nonGPCustomer && (
              <FieldReactSelect
                id="bookingDepartment"
                name="bookingDepartment"
                options={filteredLocations}
                autoComplete="bookingDepartment"
                label={'Please choose a billing code'}
                placeholder={'Choose from the options'}
                isSearchable={true}
                validate={required('This field is required')}
              />
            )}
            <div className={css.elementMargin}>
              {/*
              {isUserDeaf ? (
                <FieldTextInput
                  id="locationName"
                  name="locationName"
                  label={'Organisation to call name'}
                  placeholder={'Name of the organisation you are calling'}
                  disabled={true}
                />
              ) : null}
              */}
            </div>
            {/*}
            <div className={css.elementMargin}>
              {!isUserDeaf ? (
                <LocationAutocompleteInputField
                  name="userLocation"
                  label={'Organisation to call address'}
                  placeholder={'Organisation address'}
                  useDefaultPredictions={false}
                  format={identity}
                  validate={composeValidators(
                    autocompleteSearchRequired(addressRequiredMessage),
                    autocompletePlaceSelected(addressNotRecognisedMessage)
                  )}
                  disabled={true}
                />
              ) : null}

            </div>  */}
            {/*}
            <div className={css.elementMargin}>
              <FieldCheckbox
                id="shareConsent"
                name="shareConsent"
                label={'I give/have consent for this data to be shared.'}
                value="true"
              />
            </div>
            */}
          </div>
          <div className={css.elementMargin}>
            {!isUserDeaf && (
              <FieldCheckbox
                id="thirdPartyDetails"
                name="thirdPartyDetails"
                label={thirdPartyDetails}
                value="true"
              />
            )}
          </div>
          {values?.['thirdPartyDetails']?.[0] == 'true' ? (
            <>
              <div className={css.elementMargin}>
                <FieldTextInput
                  type="email"
                  id={'thirdPartyEmail'}
                  name="thirdPartyEmail"
                  autoComplete="thirdPartyEmail"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  validate={composeValidators(emailRequired, emailValid)}
                />
              </div>

              <div className={css.elementMargin}>
                <FieldTextInput
                  type="text"
                  id={'thirdPartyName'}
                  name="thirdPartyName"
                  autoComplete="thirdPartyName"
                  label={thirdPartyNameLabel}
                  placeholder={thirdPartyNamePlaceholder}
                  validate={required('This field is required')}
                />
              </div>
            </>
          ) : null}
          <p className={css.bottomWrapperText}>
            <span className={css.termsText}>
              {<FormattedMessage id="VideoConferencingForm.terms" values={{ termsLink }} />}{' '}
            </span>
          </p>
          {formDisabledVerifyEmail}

          <div className={css.elementMargin}>
            <Button
              className={css.submitButton}
              type="submit"
              inProgress={submitInProgress}
              disabled={submitDisabled}
              ready={submitReady}
            >
              {saveActionMsg}
            </Button>
          </div>
        </Form>
      );
    }}
  />
);

VideoConferencingForm.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

VideoConferencingForm.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    currentUserShowError: propTypes.error,
    submitError: propTypes.error,
  }),
  openTermsOfService: func.isRequired,
};

export default compose(injectIntl)(VideoConferencingForm);
